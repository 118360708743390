export const data = [
    {
        "prompt": "Create an app like Lonely Planet",
        "description": "# Lonely Planet App\nThe Lonely Planet is a mobile app than enables users to discover trips.\n\n## Feed Screen\nFeed screen features a list of posts of trips and a bottom navigation bar. The screen has a header with a title that says Trips and a list of trips displayed in a scrollable view. Each trip post has a thumbnail image and a title. The bottom navigation bar has five icons representing different sections of the app - Home, Artists, Search, Trigger, and Library. The color scheme is primarily white.",
        "url": "https://share.thecoder.live/redirect/lonelyplanet-home-screen/"
    },
    {
        "prompt": "Create a Minimal Professional Social Network App.",
        "description": "# Pro Connect App\nThe Pro Connect App empowers professionals to connect, communicate, and collaborate.\n\n## Profile Screen\nThe Profile Screen displays information about the user, including their profile picture, name, and profession. It also includes social metrics such as the number of followers, likes, and friends the user has. The screen also includes buttons to follow the user or send them a message. The profile picture is circular and slightly overlaps with the background image. The background image is blurred and serves as a backdrop for the user's information. The user can interact with the buttons to follow or message the user, and the social metrics can update dynamically as the user gains more followers, likes, or friends.",
        "url": "https://share.thecoder.live/redirect/hardest-killing-film/"
    },
    {
        "prompt": "Create an Amateur Art Discovery Platform",
        "description": "# Creative Canvas App\nUnveiling hidden artistic talent by providing a platform for discovering and showcasing art created by passionate individuals.\n\n## Explore Screen\nThe Explore Screen helps users follow Amateur Artists. The screen has a header and a feed of artist content. The header section displays the user's profile picture and a help button. The artist content section displays content from the artists, such as their names and pictures. The screen is designed with a ScrollView and various styles to create a visually appealing and functional user experience. ",
        "url": "https://share.thecoder.live/redirect/explore-user-screen/"
    },
    {
        "prompt": "Create an app for an Interior Design Company",
        "description": "# Interior Company App\nTransforming spaces with style and functionality.\n\n## Invoice Screen\nThis screen displays the work and payment details of project. The top section of the screen contains the name company name. The section below displays images of the property and site of work. The bottom section of the screen provides a breakdown of the estimated cost of the project, including a section for work in kids room, design, project, total estimate, discount, and final estimate. The screen also includes navigation buttons to view more images of the property.",
        "url": "https://share.thecoder.live/redirect/squareyard-home-screen/"
    },
    {
        "prompt": "Design a Learning App named Terafac for Industrial Automation",
        "heading": "",
        "description": "# Terafac App\nUnlocking the world of industrial automation through interactive learning on the Terafac app.\n\n## Progress Screen\nThe Progress Screen displays user's progress, showcasing the coverage of topics within the app. The app features a scrollable list of topics, with each activity card containing a title, and progress percentage. Overall, the design aims to be intuitive to enable users to track and manage their learning better.",
        "url": "https://share.thecoder.live/redirect/terafac-welcome-screen/"
    }
]
const extra = [
        // {
    //     "prompt": "",
    //     "heading": "",
    //     "description": "",
    //     "url": ""
    // },
    {
        "prompt": "Design a Course Screen",
        "heading": "",
        "description": "# Course Screen\nThe Course Screen is a React Native component that displays a list of courses and subjects in a visually appealing manner. The screen has a header with the title Courses and a button for offline courses on the top right corner. Below the header, there is a horizontal scrollable list of courses with a picture, company name, course title, and a brief description of the course. Below the course list, there is a section titled Recommended for you followed by a horizontal scrollable list of subjects with a picture and a brief description of the subject. The screen has a clean and modern design with a predominantly white background and black text. The font sizes and styles are chosen to be easy to read, and the layout is optimized for mobile devices.",
        "url": "https://share.thecoder.live/redirect/brilliant-course-screen/"
    },
    {
        "prompt": "Design a Harmonix Feed App.",
        "heading": "Harmonix App",
        "description": "The Harmonix Feed App presents a vertical scrolling list of recent posts by Smule users. Each post is displayed in a rectangular white box, featuring a user icon, the user's name, and the time of the post at the top. The box contains a cover image representing the song the user sang, along with the user's and collaborator's names (if applicable), and the song's title. The song's title is clickable, redirecting users to a page with more details about the song. The number of plays is also displayed at the bottom of the box. Users can interact with the app by clicking on the user icon, the user's name, or the reaction to send a message. The menu icon provides additional options upon clicking. While the data is being fetched from the API, the app includes a loading indicator to maintain a smooth user experience.",
        "url": "https://share.thecoder.live/redirect/smule-feed-screen/"
    },
    {
        "prompt": "Design a Harmonix SongBook app for iOS and Android.",
        "heading": "Harmonix SongBook",
        "description": "The Harmonix SongBook app showcases a list of trending recordings and song lists within the Smule community. Each song list item includes a song cover image, song name, movie name, and user name. Users can engage with the app by utilizing the Sing button, which allows them to sing the song and record their performance. The app also features a list of trending recordings, accompanied by recording cover images. To ensure a seamless user experience, the app implements a scrollable design and includes a loading indicator until the data is fetched. Tapping on an item sends a message to the parent window, containing the item data and the name of the screen, enabling a smooth transition and further exploration of the selected song.",
        "url": "https://share.thecoder.live/redirect/smule-songbook-screen/"
    },
    {
        "prompt": "Design a Spoticore search app for a mobile application.",
        "heading": "Spoticore App",
        "description": "The Spoticore Search App is designed to facilitate music track and album searches on the Spotify platform. Users can enter their search terms using the provided search bar. The search results are displayed in a flat list format, presenting two columns. Each item within the list includes an image of the album cover, the title of the track or album, and the artist's name. The app also highlights a trending event, such as a new album release or a popular playlist, at the top of the screen. This trending event is showcased with a large image, accompanied by a title and a brief description. When users interact with an item in the search results or the trending event, a message is sent with the item's details, including the image, title, and data, to another section of the app. Additionally, the message includes the name of the screen that the user clicked on, allowing for efficient tracking of user behavior and further enhancements to the app's functionality. The app incorporates a visually appealing design, with a black background, a white search bar at the top, and yellow text for the trending event title. The search result items feature a white background with black text for the title and artist's name. The album cover images are rounded, while the trending event image has a border radius of 15.",
        "url": "https://share.thecoder.live/redirect/spotify-search-screen/"
    },
    {
        "prompt": "Design a PassKey Recovery app for the Terafac application.",
        "heading": "PassKey Recovery App",
        "description": "The PassKey Recovery App for Terafac provides a user-friendly solution for recovering forgotten passwords. The app features a logo at the top, accompanied by a heading that indicates the purpose of the screen. The main components of the app include an email input field with a label, an informative message, and a Reset Password button. The email input field is designed with a gray border and includes a placeholder text. The Reset Password button stands out with a blue-green color and white text, featuring rounded corners for a modern touch. The app's design ensures a responsive layout, adapting font sizes and dimensions based on the device's width. The color scheme of the app combines blue-green and gray tones, complemented by black text for optimal readability.",
        "url": "https://share.thecoder.live/redirect/terafac-forgetpassword-screen/"
    },
    {
        "prompt": "Design the Harmonix Artist App for a mobile application.",
        "heading": "Harmonix Artist App",
        "description": "The Harmonix Artist App provides a platform to explore and discover artists through integration with the Spotify API. Users can search for artists by entering their query in the search bar. The app displays the artist list in a FlatList format, showcasing three columns per row. Each artist's profile image and name are presented within the list. Upon tapping on an artist's name or profile image, the app triggers a sendMessage function, which sends the selected artist's data and the type of selected element (text or image) to another screen called SearchResults.js. This screen is passed as a parameter in the sendMessage function, allowing for the display of the selected artist's information. To maintain a smooth user experience, the app includes an activity indicator while the artist data is being loaded.",
        "url": "https://share.thecoder.live/redirect/spotify-chooseartist-screen/"
    },
    {
        "prompt": "Design the Cinemix Search App.",
        "heading": "Cinemix Search App",
        "description": "The Cinemix Search App serves as the interface for users to search for movies, TV shows, and other content available on the Cinemix platform. The app features a search bar where users can enter keywords, and the results are displayed below in a list format. Each search result item includes the title, cover image, and a brief description of the content. Users have the option to filter search results by genre, release date, and other criteria. The app embraces a sleek and minimalistic design, prioritizing a seamless search experience. The color scheme aligns with the Cinemix brand, utilizing a black background with red accents. The app is optimized for both mobile and desktop devices, providing a responsive layout that adapts to various screen sizes.",
        "url": "https://share.thecoder.live/redirect/netflix-search-screen/"
    },
    {
        "prompt": "Design the LinkConnect MyNetwork App.",
        "heading": "LinkConnect MyNetwork App",
        "description": "The LinkConnect MyNetwork App enhances networking opportunities by displaying suggested connections and mutual connections for users. The app includes a scrollable list of profile cards featuring the user's name, profile picture, post, and the number of mutual connections. Each card incorporates a Connect button, allowing users to add the person as a connection. To facilitate searching for connections based on keywords, the app includes a search bar at the top. The suggested connections are fetched from the LinkedIn API and presented in a 2-column layout using the FlatList component. Additionally, the app displays a loading spinner during the API fetching process. The app's design adopts a clean and modern look, utilizing blue and gray colors.",
        "url": "https://share.thecoder.live/redirect/linkedin-mynetwork-screen/"
    },
    {
        "prompt": "Design a Melodio Profile App.",
        "heading": "Melodio Profile App",
        "description": "The Melodio Profile App showcases the user's musical journey, beginning with their name and profile picture at the top. Below that, a feed of the user's recordings is presented, with each recording displayed as a separate item. The items feature the recording cover, song name, and the number of views. Users can interact with the items by clicking on the user icon, user name, like icon, hash tag, or video thumbnail. Additionally, a vertical ellipsis menu icon provides further options upon clicking. The feed is scrollable, and a loader appears until the feed is loaded, ensuring a seamless experience. The app's design embraces a modern and clean aesthetic, utilizing a white primary color with black text for clarity and legibility.",
        "url": "https://share.thecoder.live/redirect/smule-profile-screen/"
    },
    {
        "prompt": "Design a Melodia Community App for code challenges, community challenges, and code challenge history.",
        "heading": "Melodia Community App",
        "description": "The Melodia Community App caters to a community of coding enthusiasts, offering a platform to participate in code challenges, community challenges, and view code challenge history. The app features a scrollable screen divided into three sections: Code Challenges, Community Challenges, and Code Challenge History. The Code Challenges section showcases a horizontal scroll view of code challenge images, indicating the corresponding challenge level. Similarly, the Community Challenges section provides a horizontal scroll view of community challenge images, accompanied by the challenge name. The Code Challenge History section presents a vertical scroll view of code challenge history images. Each section incorporates its unique styling, including a gray background, appropriate margins, padding, and shadows. To maintain a smooth user experience, the app includes loading indicators that remain visible until the data is successfully fetched.",
        "url": "https://share.thecoder.live/redirect/sololearn-community-screen/"
    },
    {
        "prompt": "Design a Vidoluxe App for video content consumption.",
        "heading": "Vidoluxe App",
        "description": "The Vidoluxe App offers a captivating video content experience, complete with an activity indicator to signify when data is being loaded. Once the data is loaded, the Watch screen presents a FlatList of videos, enticing users with a diverse range of content. Each video item showcases the user icon, user name, video thumbnail, like counts, and hashtag. Users can actively engage with each item by clicking on the user icon, user name, like icon, hashtag, or video thumbnail. Upon clicking on an item, a message is sent to the parent window, conveying the tag, key, value, item data, and screen information. This seamless integration enables users to explore and delve deeper into the selected video content.",
        "url": "https://share.thecoder.live/redirect/facebook-watch-screen/"
    }
]


// export const data = [
//     {
//         id: 0,
//         heading: 'Landing',
//         description: 'The landing screen of the TaskTrek app showcases its visually appealing logo, with an aesthetically placed next button.',
//         url: 'https://share.thecoder.live/redirect/projects-cute-entire/',
//     },
//     {
//         id: 1,
//         heading: 'Schedule Screen',
//         description: 'The schedule screen in TaskTrek app allows users to manage their tasks and appointments efficiently. It provides a visually organized view of upcoming events, deadlines, and important dates.',
//         url: 'https://share.thecoder.live/redirect/rd-baby-shower/'
//     },
//     {
//         id: 2,
//         heading: 'Task Screen',
//         description: 'The task screen of TaskTrek app is designed to help users manage individual tasks. Users can edit old tasks, assign due dates, add detailed description.',
//         url: 'https://share.thecoder.live/redirect/hamilton-patterns-twins/',
//     },
//     {
//         id: 3,
//         heading: 'Chat Screen',
//         description: 'The Chat Screen of TaskTrek app enables users to collaborate and communicate seamlessly with team members or project stakeholders. It provides a real-time messaging platform where users can exchange messages and share files.',
//         url: 'https://share.thecoder.live/redirect/subject-cloth-trainer/',
//     },
//     {
//         id: 4,
//         heading: 'Transactions Screen',
//         description: 'The transactions screen of TaskTrek app allows users to manage financial transactions related to their tasks or projects. Users can add expenses, record payments, track budgets, and generate financial reports.',
//         url: 'https://share.thecoder.live/redirect/puts-returns-leaving/',
//     },
// ]