import React, { useEffect, useState } from 'react';
import TypingEffect from './TypingEffect';
import './chat.css';
import { data } from './data';

const Chat = () => {
    const [dataIndex, setDataIndex] = useState(-1);
    const [phoneLoader, setPhoneLoader] = useState(false);
    const [phoneLoaderTransition, setPhoneLoaderTransition] = useState(false);
    const [isMoved, setIsMoved] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [inputValue, setInputValue] = useState('');
    const [isInputBoxComplete, setIsInputBoxComplete] = useState(false);
    const [timeout4, setTimeout4] = useState(null);
    const [timeout5, setTimeout5] = useState(null);
    const [timeout6, setTimeout6] = useState(null);

    

    useEffect(() => {
        const startTime = dataIndex >=0 ? 5000 : 1500
        const descriptionTime = data[Math.max(dataIndex,0)].description.length*10 - 1000
        const promptTime = data[Math.max(dataIndex,0)].prompt.length*100 - 1000

        const timeout1 = setTimeout(() => {
            setDataIndex((prevIndex) => {
                const nextIndex = (prevIndex + 1) % data.length
                console.log(nextIndex, descriptionTime + promptTime + startTime, 'Updating to nextIndex')
                return nextIndex;
            })
        },  descriptionTime + promptTime + startTime);

        const timeout2 = setTimeout(() => {
            console.log(dataIndex, startTime, 'Prompt being typed')
            setInputValue( data[(dataIndex + 1) % data.length].prompt)
        },  startTime);

        const timeout3 = setTimeout(() => {
            handleKeyPress({ key: 'Enter', descriptionTime: descriptionTime, startTime: (3000 + promptTime) });
            console.log(dataIndex, startTime + promptTime, 'Enter Key Pressed')
        }, startTime + promptTime);

        if (dataIndex >= 0){
            console.log(dataIndex, 0, 'phoneLoader true')
            setPhoneLoader(true);
            setPhoneLoaderTransition(false)
            const _timeout4 = setTimeout(() => {
                console.log(dataIndex, 3000, 'phoneLoaderTransition true')
                setPhoneLoaderTransition(true);
            }, 3000);
            setTimeout4(_timeout4)
    
            const _timeout5 = setTimeout(() => {
                console.log(dataIndex, 5000, 'phoneLoader is false')
                setPhoneLoader(false);
                setPhoneLoaderTransition(false)
            }, 5000);
            setTimeout5(_timeout5)

        } else {

            setTimeout4(null)
            setTimeout5(null)

        }
        
        return () => {
            clearTimeout(timeout1);
            clearTimeout(timeout2);
            clearTimeout(timeout3);
            clearTimeout(timeout4);
            clearTimeout(timeout5);
            clearTimeout(timeout6);
        }

    }, [dataIndex]);

  
    // useEffect(() => {
    //   setTimeout(() => {
    //     // setInputValue(data[0].prompt)
    //   }, 2000);

    //   setTimeout(() => {
    //     // handleKeyPress({ key: 'Enter' });
    //   }, 7000);
    // }, []);
  

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
          setIsMoved(true);
          setIsLoading(true);

          const _timeout6 = setTimeout(() => {
            console.log(dataIndex, event.startTime + event.descriptionTime, 'isLoading is false')
            setIsLoading(false);
            setIsInputBoxComplete(true)
          }, event.descriptionTime);
          //data[Math.max(dataIndex,0)].description.length*10)
          //data[Math.max(dataIndex,0)].description.length*10

          setTimeout6(_timeout6)

        }
      };
    
    return (
        <div className="app">
            <div className={`box ${isMoved ? 'moved' : ''}`} >
                <input
                type="text"
                placeholder="Create an app for ..."
                value={TypingEffect({text:inputValue, speed:2})}
                onChange={e => setInputValue(e.target.value)}
                className={`input-box ${isMoved ? 'moved' : ''}`}
                onKeyDown={handleKeyPress}
                />
                {isLoading ? <div className="loader"></div> : <div className="invisible"></div>}
            </div>
            {isInputBoxComplete && <div className="chat">
            <div>
                {phoneLoader && <div className={`loader-container ${phoneLoaderTransition ? 'dissolve' : ''}`}>
                    <div className={`loader ${phoneLoaderTransition ? 'dissolve' : ''}`}/>
                </div>}
                <iframe src={data[Math.max(dataIndex, 0)].url} title="App" className="iframe"/>
            </div>
            <div className="chat-container">
                {(<div>
                    {<TypingEffect text={data[Math.max(dataIndex, 0)].description} speed={3} isMarkdown={true}/>}
                </div>)}
            </div>
        </div>}
    </div>  
    );
}

export default Chat;