import { useState, useEffect } from 'react';
import { marked } from 'marked';
import { mangle } from "marked-mangle";
import { gfmHeadingId } from "marked-gfm-heading-id";

const options = {
	prefix: "thecoder-chat",
};

const TypingEffect = ({ text, speed, isMarkdown }) => {
  const [displayedText, setDisplayedText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);
  const [factor, setFactor] = useState(1.0);

  useEffect(() => {
    marked.use(gfmHeadingId(options));
    marked.use(mangle());
  }, [])

  useEffect(() => {
    setCurrentIndex(0); // Reset currentIndex when text prop changes
    setDisplayedText(''); // Reset displayedText when text prop changes
    setFactor(1.0)
  }, [text]);

  useEffect(() => {
    const animate = () => {
      setDisplayedText(prevText => text.slice(0, prevText.length + 1));
      setCurrentIndex(prevIndex => prevIndex + 1);
      setFactor(factor => factor * 1.01)
    };

    if (currentIndex < text.length) {
      const timeoutId = setTimeout(() => {
        requestAnimationFrame(animate);
      }, 100/(speed*factor));

      return () => clearTimeout(timeoutId);
    }
  }, [currentIndex, text, speed]);

  return isMarkdown ? (<div dangerouslySetInnerHTML={{ __html: marked.parse(displayedText)}}></div>) : displayedText
};

export default TypingEffect;
